html,body{
    position:relative;
    padding:0;
    margin:0;
}
._3YiVOVREwL7H63h2hCwHe{
    display:block;
    padding:0;
    margin:0;
}
input::-ms-clear{
    display:none;
}
._2hGL0M8lb0G1QuNqEGHsX1{
    position:fixed;
    left:0;
    visibility:hidden;
    overflow-y:scroll;
    -ms-overflow-style:none;
}

