._1gQBiyxwcd2W2JZxeoA1Eg{

}

._3EwYoHcI-YdsQXwJAMBtLu{
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    align-items:center;
    z-index:3;
    flex:0 0 48px;
    height:48px;
    color:#354353;
    overflow:hidden;
    clip-path:inset(0);
    align-self:stretch;
}

._5DDKHnoTc7gL_iObM5Tvs{

}

._2qIJVVlw1V8HzUteDDopgb{
    display:none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    ._2qIJVVlw1V8HzUteDDopgb{
        position:absolute;
        height:100vh;
        bottom:auto;
    }
}

@supports (-ms-ime-align:auto){
    ._2qIJVVlw1V8HzUteDDopgb{
        position:absolute;
        height:100vh;
        bottom:auto;
    }
}

._3DTyPk66FDymjFnJEAy7Wi{
}

._2fao-NKTj1Al-kYAagWEGR{
}

.OEmK8EduCF9-kuZ2eUzdI{
}

._766tjDQ2s3WhQdUdgNCVE{
    font-size:14px;
    margin-left:34px;
    flex:1 1 auto;
    padding:0;
    z-index:11;
    text-overflow:ellipsis;
    white-space:nowrap;
    overflow:hidden;
    position:relative;
}

.IHhb_mGnCD43suQNU_agw{
    flex:0 0 auto;
    margin:0 10px 0 1em;
    height:20px;
    z-index:11;
    display:flex;
    flex-direction:row;
    align-items:baseline;
}

.SCJnalIet3mYykn14aEm3{
    flex:0 0 auto;
    margin:0 22px 0 10px;
    position:relative;
    z-index:11;
    right:10px;
    width:31px;
    border:none;
    height:31px;
}

.SCJnalIet3mYykn14aEm3 > input{
    font-family:'Open Sans', 'Lucida Grande', Helvetica, 'Lucida Sans', Arial, sans-serif;
    font-size:13px;
    border:solid 1px rgba(39, 47, 56, 0.16);
    height:24px;
    margin:3px 0;
    vertical-align:baseline;
    color:#354353;
    line-height:31px;
    outline:0;
    border-radius:3px;
    background:transparent;
    display:none;
    padding:0 20px 0 10px;
    box-sizing:border-box;
}

._2ON2ekxCoPMv-X4P1YcQB9{
    width:160px
}

._2ON2ekxCoPMv-X4P1YcQB9 > input{
    display:block;
    width:100%;
    box-sizing:border-box;
}

.SCJnalIet3mYykn14aEm3 > input::-ms-input-placeholder{
    color:#354353;
    opacity:.5;
}

.SCJnalIet3mYykn14aEm3 > input::-webkit-input-placeholder{
    color:#354353;
    opacity:.5;
}

.SCJnalIet3mYykn14aEm3 > input::placeholder{
    color:#354353;
    opacity:.5;
}

._2_83x_nelgxp1Pjl3f2geh{
    cursor:pointer;
    width:31px;
    height:31px;
    display:block;
    border-radius:90px;
}

._2_83x_nelgxp1Pjl3f2geh:hover{
    background:rgba(183, 188, 194, 0.5);
}

._2JA5RMFiW1EPict_Lh5Dod{
    display:block;
    position:absolute;
    top:6px;
    right:8px;
    height:12px;
    width:12px;
    border:2px solid #1f2235;
    pointer-events:none;
    border-radius:100px;
}

._2kKcHV4vbRDoRKyKTWBs3c{
    display:block;
    position:absolute;
    top:20px;
    right:6px;
    height:0;
    border:1px solid #1f2235;
    width:4px;
    pointer-events:none;
    transform:rotate(45deg);
}

._2ON2ekxCoPMv-X4P1YcQB9 ._2_83x_nelgxp1Pjl3f2geh{
    position:absolute;
    right:5px;
    top:0;
    width:21px;
    height:21px;
    margin:5px 0;
}

._2ON2ekxCoPMv-X4P1YcQB9 ._2_83x_nelgxp1Pjl3f2geh:hover{
    background:none;
}

._2ON2ekxCoPMv-X4P1YcQB9 ._2JA5RMFiW1EPict_Lh5Dod{
    display:block;
    position:absolute;
    top:9px;
    right:4px;
    height:0;
    width:12px;
    border:1px solid;
    pointer-events:none;
    border-radius:0;
    transform:rotate(-45deg);
    border-width:2px 0 0 0;
}

._2ON2ekxCoPMv-X4P1YcQB9 ._2kKcHV4vbRDoRKyKTWBs3c{
    display:block;
    position:absolute;
    top:9px;
    right:4px;
    height:0;
    width:12px;
    border:1px solid;
    pointer-events:none;
    transform:rotate(45deg);
    -ms-transform:rotate(45deg) scale(1,1.2);
    border-width:2px 0 0 0;
}

._3B3UIDnGGErMyY4brjxXzQ{
}

._3WNkm221U_VuB2K1y6KdIN{
    display:inline-block;
    cursor:default;
    border:none;
    padding:0;
    margin:0;
    align-self:center;
}

._2TJY3rSVbS45Va3pdqVUVL{
    display:flex;
    line-height:20px;
    margin:0;
    align-items:center;
}

._3Qc4oaPc_75Ii9CVkECxtD{
    flex:1 1 auto;
    display:flex;
    align-items:center;
}

._1RPMCgdIBAO1Hej6ljPZA5{
}

._3qPZB1Jftwuk9tJ48Rapbh{
    font-size:30px;
    line-height:11.5px;
    border-radius:90px;
    width:31px;
    height:25px;
    text-align:center;
    color:rgba(53, 67, 83, 0.55);
    margin:0;
    display:flex;
    flex-direction:column;
    align-items:center;
    padding:3px 0;
    cursor:pointer;
}

._3qPZB1Jftwuk9tJ48Rapbh:hover{
    background:rgba(183, 188, 194, 0.5);
}

._3qPZB1Jftwuk9tJ48Rapbh > span{
    background:#354353;
    display:block;
    flex:0 0 3px;
    width:3px;
    height:3px;
    border-radius:50%;
    margin:4px 0 0 0;
}

._2HugHQTW65M56y96iRdt1H{
    background:rgba(0, 0, 0, .12);
}

._3sh41PtRGi2vMlEex4ffui{
    position:absolute;
    background:#f6f6f6;
    font-size:13px;
    line-height:1.3em;
    width:115px;
    border-radius:3px;
    box-shadow:0 3px 10px 0 rgba(35, 49, 67, 0.42);
}

._29NENg-1Bsm8SDeqSZT4Xl{
    display:flex;
    align-items:center;
    padding:10px;
    cursor:default;
}

._29NENg-1Bsm8SDeqSZT4Xl:hover{
    background:rgba(183, 188, 194, 0.25);
}

._35RhsT78koa-M0zBQZBop4{
    background:rgba(183, 188, 194, 0.5);
}

._2pVJEpGqcF_BBzxKfKS0xV{
    border-bottom:1px solid #e2e4e7;
}

._2pVJEpGqcF_BBzxKfKS0xV:hover{
    background:transparent;
}

._1zv7CzxCIVv57LC_UFJpUY{
    display:flex;
    align-items:center;
}

._2wPaoA3hcEWQe-jvKoU-Q3{
}

._2wPaoA3hcEWQe-jvKoU-Q3 ._3EwYoHcI-YdsQXwJAMBtLu{
}

._2wPaoA3hcEWQe-jvKoU-Q3 ._766tjDQ2s3WhQdUdgNCVE{
    text-align:left;
    font-size:16px;
    padding:2px 100px 0 15px;
    margin-left:0;
}

._2wPaoA3hcEWQe-jvKoU-Q3 ._2_83x_nelgxp1Pjl3f2geh{
    right:12px;
}

._2wPaoA3hcEWQe-jvKoU-Q3 .SCJnalIet3mYykn14aEm3{
    margin:0 0 0 1em;
}

._2wPaoA3hcEWQe-jvKoU-Q3 ._2ON2ekxCoPMv-X4P1YcQB9 ._2_83x_nelgxp1Pjl3f2geh{
    right:23px;
    top:8px;
}

._2wPaoA3hcEWQe-jvKoU-Q3 .SCJnalIet3mYykn14aEm3 > input{
    font-size:16px;
    height:35px;
}

._2wPaoA3hcEWQe-jvKoU-Q3 ._2SuhN5xfDJj0iubN_mgCVx{
    display:none;
}

._2wPaoA3hcEWQe-jvKoU-Q3 ._2ON2ekxCoPMv-X4P1YcQB9{
    position:relative;
    top:0;
    right:0;
    bottom:0;
    left:0;
    padding:3px 16px 0 8px;
    flex:1 1 auto;
    align-self:start;
}

._2wPaoA3hcEWQe-jvKoU-Q3 ._2TJY3rSVbS45Va3pdqVUVL{
    margin:0;
    padding:0;
    width:auto;
    border:none;
}

._2wPaoA3hcEWQe-jvKoU-Q3 ._1zv7CzxCIVv57LC_UFJpUY{
    margin:0;
}

._2wPaoA3hcEWQe-jvKoU-Q3 ._1zv7CzxCIVv57LC_UFJpUY > *:first-child{
    flex:1 1 100%;
}

._2wPaoA3hcEWQe-jvKoU-Q3 ._1zv7CzxCIVv57LC_UFJpUY > ._1RPMCgdIBAO1Hej6ljPZA5{
    flex:0 0 auto;
}

._2wPaoA3hcEWQe-jvKoU-Q3 ._2pVJEpGqcF_BBzxKfKS0xV{
    pointer-events:none;
}
