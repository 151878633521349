.DoZC3FN7Gs4rvYBTgrHAj{
  overflow:hidden !important;
  overflow-anchor:none;
  -ms-overflow-style:none;
  touch-action:auto;
  -ms-touch-action:auto;
}
._269nnfIZEy7bnG-KSHSG_a{
}
._2eKwCdZQki_HsVuaC5i_Be{}
._3tH0EAbriQc20zZ0gVcVLE{
  display:none;
  opacity:0;
  transition:background-color .2s linear, opacity .2s linear;
  -webkit-transition:background-color .2s linear, opacity .2s linear;
  position:absolute;
  z-index:10000;
}
._6VhiNReNUDykO-RvSKaQM{
  height:15px;
  bottom:0px;
}
._1FmCFssP98k5eKmo8h6mYc{
  width:15px;
  right:0;
}
.US3QpMnCc6ovUcIH7FGdj > ._6VhiNReNUDykO-RvSKaQM,
._3kWHwC55tII3_iyZg0CMO2 > ._1FmCFssP98k5eKmo8h6mYc{
  display:block;
  background-color:transparent;
}
.DoZC3FN7Gs4rvYBTgrHAj:hover > ._6VhiNReNUDykO-RvSKaQM,
.DoZC3FN7Gs4rvYBTgrHAj:hover > ._1FmCFssP98k5eKmo8h6mYc,
._2keHze7ccnWAPQmg76BXXT > ._6VhiNReNUDykO-RvSKaQM,
._2keHze7ccnWAPQmg76BXXT > ._1FmCFssP98k5eKmo8h6mYc,
._1LIWrx5lAtOX8VxRbEtiAL > ._6VhiNReNUDykO-RvSKaQM,
._37eezYYpj0VWOLQOd_3Odb > ._1FmCFssP98k5eKmo8h6mYc{
  opacity:0.6;
}
.DoZC3FN7Gs4rvYBTgrHAj ._6VhiNReNUDykO-RvSKaQM:hover,
.DoZC3FN7Gs4rvYBTgrHAj ._1FmCFssP98k5eKmo8h6mYc:hover,
.DoZC3FN7Gs4rvYBTgrHAj ._6VhiNReNUDykO-RvSKaQM:focus,
.DoZC3FN7Gs4rvYBTgrHAj ._1FmCFssP98k5eKmo8h6mYc:focus,
.DoZC3FN7Gs4rvYBTgrHAj ._6VhiNReNUDykO-RvSKaQM._2mA_DHskf2voi2CsOVv9UG,
.DoZC3FN7Gs4rvYBTgrHAj ._1FmCFssP98k5eKmo8h6mYc._2mA_DHskf2voi2CsOVv9UG{
  background-color:#eee;
  opacity:0.9;
}
.nJjSn2pf4hWiEdW8ZvujM{
  background-color:#aaa;
  border-radius:6px;
  transition:background-color .2s linear, height .2s ease-in-out;
  -webkit-transition:background-color .2s linear, height .2s ease-in-out;
  height:6px;
  bottom:2px;
  position:absolute;
}
._39rRUPnbFGhHL36rJksRXg{
  background-color:#aaa;
  border-radius:6px;
  transition:background-color .2s linear, width .2s ease-in-out;
  -webkit-transition:background-color .2s linear, width .2s ease-in-out;
  width:6px;
  right:2px;
  position:absolute;
}
._6VhiNReNUDykO-RvSKaQM:hover > .nJjSn2pf4hWiEdW8ZvujM,
._6VhiNReNUDykO-RvSKaQM:focus > .nJjSn2pf4hWiEdW8ZvujM,
._6VhiNReNUDykO-RvSKaQM._2mA_DHskf2voi2CsOVv9UG .nJjSn2pf4hWiEdW8ZvujM{
  background-color:#999;
  height:11px;
}
._1FmCFssP98k5eKmo8h6mYc:hover > ._39rRUPnbFGhHL36rJksRXg,
._1FmCFssP98k5eKmo8h6mYc:focus > ._39rRUPnbFGhHL36rJksRXg,
._1FmCFssP98k5eKmo8h6mYc._2mA_DHskf2voi2CsOVv9UG ._39rRUPnbFGhHL36rJksRXg{
  background-color:#999;
  width:11px;
}
@supports (-ms-overflow-style: none){
  .DoZC3FN7Gs4rvYBTgrHAj{
    overflow:auto !important;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
  .DoZC3FN7Gs4rvYBTgrHAj{
    overflow:auto !important;
  }
}
