._2XXSzmLoxg2YCKXWlsqik-{}
.qNHq2E5k3T_1x7gjtcIk3{}
._1HEm0KBFTxEI03wh9LyJVJ{}
._2StmQtAMj95VcRZwDZoQOn{}
._1bWzkMZzP2i6gseMPzomwA{}
._3KI7oqXP5z-lys9ZMv5TZ0{
    overflow:hidden;
}
._1njZiWRfBPXXytR9Rvy4wF{
    overflow:visible;
}
._2GbbFwXyvMPMlcc_lbgh0Y{}
._1Lgke4fH4_j0CImrDUs8QV{}
.zdo5nev5S2zf-BOP1OlQf{}
._1ZkGxghA3sZiSa-cMxjWrB{}
._2UFbAjo5aV17C9clT1fVxQ{}
.xxsJzcc7yTCNE81HUsRi5{}
._2HS5pq_sG-4tAB_x0bbn6Z{
    width:100%;
    user-select:none;
    font-family:'Open Sans', 'Lucida Grande', Helvetica, 'Lucida Sans', Arial, sans-serif;
    font-size:13px;
    font-variant:normal;
    font-style:normal;
    font-stretch:normal;
    font-kerning:normal;
    font-weight:normal;
}
._2HS5pq_sG-4tAB_x0bbn6Z :focus{
    outline:black auto 1px;
}
._2HS5pq_sG-4tAB_x0bbn6Z :focus:not(:focus-visible){
    outline:none;
}
._2HS5pq_sG-4tAB_x0bbn6Z :focus:not(:-moz-focusring){
    outline:none;
}
._3rMPMMabuGdOdL8F8Tu_Sw{
    background-size:cover;
    background-position:center center;
    background-repeat:no-repeat;
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    pointer-events:none;
}
._3KI7oqXP5z-lys9ZMv5TZ0 ._3rMPMMabuGdOdL8F8Tu_Sw{
    position:absolute;
}
._2PBMbRxLNkadJCVgD7ucbd{

}
._1VVBEiJ0Yq6pgVYuxEH73x{

}
.uXxu-bzUTWb1odIpixRM0{

}
._26loA_FcM6HHITB2pSBeWT{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    height:100vh;
}
._3KI7oqXP5z-lys9ZMv5TZ0 ._26loA_FcM6HHITB2pSBeWT{
    height:auto;
}
._1njZiWRfBPXXytR9Rvy4wF ._26loA_FcM6HHITB2pSBeWT{
    height:auto;
    position:relative;
    top:auto;
    left:auto;
    right:auto;
    bottom:auto;
}
._3dtkuZRhOwXMmOMqrnm-tl{
    position:absolute;
    z-index:10;
    pointer-events:none;
}
._1KZnUHs1TNepYdOpkvIuQk{
    left:0;
    top:0;
    bottom:0;
    background-repeat:repeat-y;
}
.qTBFas_bcZN6F3REqDPoH{
    right:0;
    top:0;
    bottom:0;
    background-repeat:repeat-y;
}
.oOfWIDyhLY4Qt1d7QSIl3{
    right:0;
    top:0;
    bottom:0;
    left:0;
    background-repeat:repeat;
}
._3uGmMbvvNxIUREFzsChJJ0{
    position:fixed;
    left:0;
    right:0;
    top:0;
    bottom:0;
    overflow:auto;
    overflow-x:hidden;
}
._3Pi_oGjb6y_sHpe3qCDPrg{}
._3Pi_oGjb6y_sHpe3qCDPrg ._3uGmMbvvNxIUREFzsChJJ0{
    position:absolute;
}
._3KI7oqXP5z-lys9ZMv5TZ0 ._3uGmMbvvNxIUREFzsChJJ0{
    position:absolute;
}
._2psU0HrwTh2gCSji6ZkZiD{
    top:48px;
}
._1njZiWRfBPXXytR9Rvy4wF ._3uGmMbvvNxIUREFzsChJJ0{
    position:relative;
    top:0;
    left:auto;
    right:auto;
    bottom:auto;
    overflow:auto;
    overflow-x:hidden;
}
.TUm451gC6VGO7LD-2FiFC{
    margin-left:auto;
    margin-right:auto;
    z-index:1;
    margin-top:50px;
    position:relative;
}
._2StmQtAMj95VcRZwDZoQOn .TUm451gC6VGO7LD-2FiFC, ._1bWzkMZzP2i6gseMPzomwA .TUm451gC6VGO7LD-2FiFC, ._1HEm0KBFTxEI03wh9LyJVJ .TUm451gC6VGO7LD-2FiFC, .qNHq2E5k3T_1x7gjtcIk3 .TUm451gC6VGO7LD-2FiFC{
    margin-bottom:0;
}
.TPN8OigkaXUbB-rlCVBNZ{
    background-position:left top;
}
.mHsjFQ9n7EuALSYEvXCf1{
    background-position:left top;
    right:50%;
    top:0;
    bottom:0;
}
._1HhK9xlgKFf_qtypvZTpS1{
    background-position:right top;
    left:50%;
    top:0;
    bottom:0;
}
._2lpFcNcuCKtbmqy1XJSVpi{
    background-position:right top;
}
._1ij55_HT-zMQsofa-161Jj{
    background-repeat:no-repeat;
}
._2LGvWQKiYWh-CUVMMWY93U{
    background-repeat:repeat-x;
    right:50%;
    top:0;
    bottom:0;
    background-position:top left;
}
._3FtR3_nwRbuYDeBH1hM6OM{
    background-repeat:repeat-x;
    left:50%;
    top:0;
    bottom:0;
    background-position:top right;
}
._2kMxkCgohBBJhr8A3HcU8h{
    background-repeat:no-repeat;
    background-position:top right;
}
._3tEINMTWONTHWc79kOzM_H{
    z-index:11;
    position:relative;
    display:flex;
    flex-wrap:wrap;
    flex-direction:column;
    align-items:center;
}
._2_zF17TH0n2gtnd-hBNmcM{
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
}
._10iDlKgs13bpaOix1Dhb7g{
    position:absolute;
    bottom:0;
    margin:0 auto 0 auto;
    z-index:0;
}
._2MZnVkNLkVYW1R6G3VMUQz{
}
._3qsqZAs1LFjCUF55MH9fzE{
    right:50%;
}
._2LNepIn5nQuAhz9IWe6A-_{
    left:50%;
}
._3f1sT01Jc4XZRZ8AHm757B{
}
._3M6EX-eWl2gboXDecZMqsF{
    position:fixed;
    overflow:visible;
    z-index:10001;
}
@media (hover: none){
    ._3M6EX-eWl2gboXDecZMqsF{
        display:none;
    }
}
._3eDaN8MjcwQv95G9Qq9F_F{
}
._1OJs6yKo8cP9wB1xlkGPnh{
}
._2UFbAjo5aV17C9clT1fVxQ ._1OJs6yKo8cP9wB1xlkGPnh{
    display:none !important;
}
.xxsJzcc7yTCNE81HUsRi5 ._1OJs6yKo8cP9wB1xlkGPnh{
    display:block !important;
}
._2UFbAjo5aV17C9clT1fVxQ ._3eDaN8MjcwQv95G9Qq9F_F{
    display:block !important;
}
.xxsJzcc7yTCNE81HUsRi5 ._3eDaN8MjcwQv95G9Qq9F_F{
    display:none !important;
}
