.j_ip_Of2sgQ1Gp64IVSaW{
    box-shadow:0 27px 66px rgba(25, 31, 41, .3);
    margin-bottom:60px;
}

._6FRuUtLmsFcF6FqftwtZQ{
    background-image:url(https://d3m86d30627p3p.cloudfront.net/Content/R10008/dist/f8c416da9b124857bbdc15fc7a273e9a.png);
    width:333px;
    left:0;
}

._1-X_2lntoPcU46S-JLHlLU{
    background-image:url(https://d3m86d30627p3p.cloudfront.net/Content/R10008/dist/5914943ef36c500c4c98b2f325355788.png);
    left:332px;
}

._237bL_b0A-yui-UOp5Zoxi{
    background-image:url(https://d3m86d30627p3p.cloudfront.net/Content/R10008/dist/5914943ef36c500c4c98b2f325355788.png);
    right:332px;
}

._3PY0iRv66DkpXNA_5k1RvN{
    background-image:url(https://d3m86d30627p3p.cloudfront.net/Content/R10008/dist/ba8efd6901a908ac608198c092b4e44b.png);
    width:333px;
    right:0;
}

._22FXAyR1FcEhJg-GPlbKaY{
    background-image:url(https://d3m86d30627p3p.cloudfront.net/Content/R10008/dist/85475e8eb163bd4f7c2234cba1d15e1a.png);
    width:333px;
    top:279px;
    left:0;
}

._3-GndITXZhQ2x6EKECkCoU{
    background-image:url(https://d3m86d30627p3p.cloudfront.net/Content/R10008/dist/f872d80934e19e47e27e9637de6f45b8.png);
    left:332px;
    top:279px;
}

._1cRqDr80PvAL8WiTyV1-me{
    background-image:url(https://d3m86d30627p3p.cloudfront.net/Content/R10008/dist/f872d80934e19e47e27e9637de6f45b8.png);
    right:332px;
    top:279px;
}

._204OXGfEexC5dw7KeT0WLF{
    background-image:url(https://d3m86d30627p3p.cloudfront.net/Content/R10008/dist/df3f058c26669d5c455c6ee79a525f89.png);
    width:333px;
    top:279px;
    right:0;
}

._2FMk5Pxmp9LhS67mSgDxxE{
    padding:33px 50px 0 50px;
    margin:0 auto;
    min-height:246px;
    justify-content:center;
    min-width:666px;
}

._3qPb8z4quUmak3-aTtMlsV{
}

._2ypJ_fh6l0VWSCkXgdT-R1{
}

._1BcFueUS6kypQ4Yc91RPME ._2ypJ_fh6l0VWSCkXgdT-R1{
    margin:0 auto;
}

.KQ-oSySOOqaeUku6sOCxQ ._2ypJ_fh6l0VWSCkXgdT-R1{
    margin:0 auto;
}

.KQ-oSySOOqaeUku6sOCxQ .j_ip_Of2sgQ1Gp64IVSaW{
    margin-top:25px;
    box-shadow:none;
}

.KQ-oSySOOqaeUku6sOCxQ ._2FMk5Pxmp9LhS67mSgDxxE{
    padding:33px 2px 0 2px;
    min-width:155px;
}

.KQ-oSySOOqaeUku6sOCxQ ._6FRuUtLmsFcF6FqftwtZQ{
    display:none;
}

.KQ-oSySOOqaeUku6sOCxQ ._3PY0iRv66DkpXNA_5k1RvN{
    display:none;
}

.KQ-oSySOOqaeUku6sOCxQ ._22FXAyR1FcEhJg-GPlbKaY{
    display:none;
}

.KQ-oSySOOqaeUku6sOCxQ ._204OXGfEexC5dw7KeT0WLF{
    display:none;
}

.KQ-oSySOOqaeUku6sOCxQ ._1-X_2lntoPcU46S-JLHlLU{
    left:0;
}

.KQ-oSySOOqaeUku6sOCxQ ._237bL_b0A-yui-UOp5Zoxi{
    right:0;
}

.KQ-oSySOOqaeUku6sOCxQ ._3-GndITXZhQ2x6EKECkCoU{
    left:0;
}

.KQ-oSySOOqaeUku6sOCxQ ._1cRqDr80PvAL8WiTyV1-me{
    right:0;
}

.KQ-oSySOOqaeUku6sOCxQ ._3N1kPNWjGtNbbEsYd22O5i{
    display:none;
}

.KQ-oSySOOqaeUku6sOCxQ ._28fwQYsJnD5iWuUYa3ytei{
    display:none;
}

.KQ-oSySOOqaeUku6sOCxQ ._erWCPpql7RZQvOEzQywU{
    left:0 !important;
}

.KQ-oSySOOqaeUku6sOCxQ .W1qYfyKMUiOemPbl56Zpb{
    right:0 !important;
}

._2DxBhfnYV_l5wAXOC2xqQs{
    height:0px;
    margin-top:0;
    margin-bottom:0;
    position:relative;
    bottom:auto;
}

