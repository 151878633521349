._1VcF79NIcJqdbqpwieELMs{
    width:155px;
    height:241px;
}

._3GohWH4TCsv0uYYA6c2c8K{
    background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIQAAAAICAYAAAA4NlcEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAACzSURBVHja7FfRCsMwCLzL+v9/3LhXJ7dVadgyUDji3bUg1pCU+BzE72OHGlaG7VwDE3nFy/CKVhkK/sGHtqKX0Sr80jtcM1cDb7QRPJVn1spA3x0aS+hXeWZV+Qyaem4FAMCO0Ojh8Ajcg86n8KLutahT+NkhUxyCZwcGxd1lgluCK0zBp/BnyL3m+Sk8j1O8x4GODrFT+sjoI8MAWF8q+1L5knPzJvdv55dreAIAAP//AwCLE5oTYp3vKgAAAABJRU5ErkJggg==) no-repeat bottom center;
    position:absolute;
    bottom:-1px;
    left:0;
    right:0;
    height:8px;
}

._1W5X0sSgtkSglAKn4L2wnQ{
    background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAcCAYAAAC+lOV/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAATZJREFUeNqUVQFOwzAMjN2A2P8/ijYYUNqapLKl283pRqRTtsRnX2LHLeUfw8x2xJCGCfcf8XGuDW9EsgPC1rC6CuvkExiIGyARsfi8isge+YW8TwMHm0Nio5M1iaJADoL5HDAlw9jgNcOIMRRmBQOB/+yssGxLIhhI3sDRTWQ+SyHJhdYFyVNy5oygWeTiDgTWZERgsialKgMHd5GFCkIG8g9TxWkqg+JIZWcFUxKSZJFHNz6UXQflZ4NowuRXryB8PfF7hRc38QVXMGCgk7TLaPIIOMcKjWCOhtB7GT4MJblxF78NV8cciqKTlKRrRBP4avhw4hoROzHOnJ2pG342XBq+o+nt1+3EkI2p2lzaxTFTP7vLM/aqn4azY3nUxyukpZ/r3eUuz3xBqhPPTrweyeTxJ8AA7iqFdOgHYQsAAAAASUVORK5CYII=);
    background-color:transparent;
    box-shadow:none;
    height:15px;
    right:-15px;
    width:15px;
    left:auto;
}

._2xELJuQJEGjFLr1oohm8gy{
    background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAABCAYAAAAB3PQ6AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACZJREFUeNpiZGBg0ADi/wwQ8A+K/6NhkNgfIP4JxN+A+BdIDCDAAE/RDQJlzn2dAAAAAElFTkSuQmCC);
    box-shadow:none;
    right:-15px;
    width:15px;
    top:20px;
    bottom:13px;
}

._2b1mgRXUTRqjsWqEW2aRqm{
    background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAcCAYAAAC+lOV/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAATZJREFUeNqUVQFOwzAMjN2A2P8/ijYYUNqapLKl283pRqRTtsRnX2LHLeUfw8x2xJCGCfcf8XGuDW9EsgPC1rC6CuvkExiIGyARsfi8isge+YW8TwMHm0Nio5M1iaJADoL5HDAlw9jgNcOIMRRmBQOB/+yssGxLIhhI3sDRTWQ+SyHJhdYFyVNy5oygWeTiDgTWZERgsialKgMHd5GFCkIG8g9TxWkqg+JIZWcFUxKSZJFHNz6UXQflZ4NowuRXryB8PfF7hRc38QVXMGCgk7TLaPIIOMcKjWCOhtB7GT4MJblxF78NV8cciqKTlKRrRBP4avhw4hoROzHOnJ2pG342XBq+o+nt1+3EkI2p2lzaxTFTP7vLM/aqn4azY3nUxyukpZ/r3eUuz3xBqhPPTrweyeTxJ8AA7iqFdOgHYQsAAAAASUVORK5CYII=);
    box-shadow:none;
    background-position:bottom right;
    height:13px;
    right:-15px;
    width:15px;
    left:auto;
    top:auto;
    bottom:0;
}

._3tMcrHMf6J_FRfuAtaHTlG{
    position:absolute;
    bottom:5px;
    z-index:20;
    text-align:left;

    opacity:.9;
    font-size:11px;
    color:#fff;
    border:2px solid #1d5a9c;
    border-top-color:#d5e2ef;
    width:220px;
    margin-left:-110px;
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#6ca4df', endColorstr='#3f77b2');
    background:#568ec9;
    background-image:-moz-linear-gradient(top, #6ca4df 1%, #3f77b2 100%);
    background-image:-webkit-linear-gradient(top, #6ca4df 1%, #3f77b2 100%);
    background-image:linear-gradient(top, #6ca4df 1%, #3f77b2 100%);
}

.Gf50_-D_OHo-w-GKgig7h{
    border-radius:7px;
    width:100%;
    box-shadow:inset 0 -1px 0 rgba(255, 255, 255, 0.4);
    padding:0;
}

._3dMc3wuI_TlyDQNou57dZF{
    box-shadow:inset 0 1px 1px rgba(0, 0, 0, 0.4);
    overflow:hidden;
    padding-bottom:4px;
}

._2M2z-utFsi5s86kZYkQXIw{
    display:none !important;
}

.yrs6VnRZZxtARdpRU9_7H{
    display:none !important;
}

._3dMc3wuI_TlyDQNou57dZF h3{
    margin:6px 6px 0 6px;
    font-size:14px;
    font-weight:700;
}

._3dMc3wuI_TlyDQNou57dZF p{
    bottom:0;
    left:100px;
    margin:6px 6px 3px 6px;
}

._1FnaqFrIKnTaWdKUWxdqxr{
    left:100px;
    margin-bottom:-20px;
    border-top-color:#1d5a9c;
}

._1vgFp7MhXs5JLs4w_kqqRV{
    border-width:7px;
    margin-bottom:-13px;
    border-top-color:rgba(255, 255, 255, 0.4);
    z-index:301;
    margin-left:3px;
}

.as_BjktkQGP5ElHKClpuf{
    border-width:7px;
    margin-bottom:-11px;
    border-top-color:#3f77b2;
    z-index:302;
    margin-left:3px;
}
