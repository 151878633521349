
._1WwYmvlKZ2XX5tRjQppGm5{
    position:fixed;
    width:100%;
    height:100%;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:100;
    overflow:hidden;
    background:rgba(0, 0, 0, .1);
}
